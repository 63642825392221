import {GROUP_ORDER_HISTORY_LIST_STATE_NAME} from './components/customer/orderHistory/orderHistoryListWrapper/order.history.list.wrapper.config';
import {STATE_OR_NAME as NEW_CONNECT_PAGE} from './reducers/constants/new.connect.wizard.constants';
import {STATE_OR_NAME as ADD_OFFERING_PAGE, ADD_OFFERING_INSTANCES, REVIEW_SAVED_CART, REVIEW_SAVED_CART_INSTANCES, REVIEW_STORE_ORDER_ADD} from './reducers/constants/add.offer.wizard.constants';
import {STATE_OR_NAME as EDIT_OFFERING_PAGE, EDIT_OFFERING_INSTANCES, REVIEW_STORE_ORDER_EDIT, RECONNECT_OFFER_STATE_NAME} from './reducers/constants/edit.offer.wizard.constants';
import {STATE_OR_NAME as CHANGE_OFFERING_PAGE} from './reducers/constants/transition.offer.wizard.constants';
import {REVIEW_STORE_ORDER_TRANSITION} from './components/customer/orders/transition/transition.offer.wizard.constants';
import {STATE_OR_NAME as E_WALLET_PAGE} from './components/customer/ewallet/ewallet.config';
import {DISCONNECT_CUSTOMER_DBSS_ROUTE, DISCONNECT_OFFERS_DBSS_ROUTE} from './components/customer/offers/disconnect/dbss/disconnect.offers.dbss.config';
import {TRANSFER_STATE_NAME} from './components/customer/orders/transfer/transfer.wizard.config';
import {OFFERINGS_STATE_NAME} from './components/customer/offerings/offerings.config';
import {SERVICE_DETAILS_STATE_NAME} from './components/customer/servicesAndUsage/services.and.usage.config';
import {DASHBOARD_ROUTE} from './reducers/constants/dashboard.constants';
import {CREATE_PRODUCTS_ORDER_STATE_NAME} from './reducers/constants/products.wizard.constants';
import {DETAIL_STATE_OR_NAME as PRODUCT_DETAILS_PAGE_REDIRECT} from './components/customer/products/products.config';

const CUSTOMER = '/customer/:customerId';
const ORDERING = `${CUSTOMER}/ordering`;
export const HOME = '/';
export const SERVICES_AND_USAGES = `${CUSTOMER}/services-and-usages`;
export const SERVICES = `${SERVICES_AND_USAGES}/services`;

const ADDRESSES_STATE_REDIRECT = 'index.customercare.customer.addresses';
const RATE_SHEET_STATE_REDIRECT = 'index.customercare.customer.rateSheet';
const ACCOUNT_HIERARCHY_STATE_REDIRECT = 'index.customercare.customer.accountHierarchyRedirect';
export const ACCOUNT_HIERARCHY_NODE_SERVICES_PAGE_REDIRECT = 'index.customercare.customer.accountHierarchyNodeServicesRedirect';
export const E_WALLET_STATE_REDIRECT = 'index.customercare.customer.eWalletRedirect';
export const CASES_LIST_STATE_REDIRECT = 'index.customercare.customer.casesRedirect';
const CASES_DETAILS_STATE_REDIRECT =
    'index.customercare.customer.cases.detailsRedirect';

const INVOICE_MESSAGES_LIST_STATE_REDIRECT = 'index.customercare.customer.invoiceMessagesRedirect';
const INVENTORY_LIST_STATE_REDIRECT = 'index.customercare.customer.inventory';

const INTERACTIONS_LIST_STATE_REDIRECT =
    'index.customercare.customer.interactionsRedirect';
const INTERACTIONS_DETAILS_STATE_REDIRECT =
    'index.customercare.customer.interactions.detailRedirect';

const NOTIFICATIONS_LIST_STATE_REDIRECT = 'index.customercare.customer.notificationsRedirect';

export const INVENTORY_DETAILS_STATE_REDIRECT = 'index.customercare.customer.inventoryDetails';
export const PRICING_PLAN_EARLY_RENEWAL_REDIRECT = 'index.customercare.customer.pricingPlanEarlyRenewal';
export const DBSS_PRODUCT_DETAILS_PAGE_REDIRECT = 'index.customercare.customer.dbss-products.redirect';
export const SUSPEND_SUBSCRIBER_PRODUCTS_REDIRECT = 'index.customercare.customer.suspendSubscriberProducts.redirect';
export const RESUME_SUBSCRIBER_PRODUCTS_REDIRECT = 'index.customercare.customer.restoreSubscriberProducts.redirect';
export const SUSPEND_SERVICES_REDIRECT = 'index.customercare.customer.suspendServices.redirect';
export const RESUME_SERVICES_REDIRECT = 'index.customercare.customer.resumeServices.redirect';
export const USAGE_DETAILS_NON_TELCO_SERVICES_REDIRECT = 'index.customercare.customer.nonTelcoUsageServices.redirect';
export const SERVICE_ADD_ON_PRODUCTS_ORDERING_PAGE_ROUTE = 'index.customercare.customer.serviceAddonsOrdering';
export const INVENTORY_ADD_ON_PRODUCTS_ORDERING_PAGE_ROUTE = 'index.customercare.customer.inventoryAddonsOrdering';
export const PRODUCT_LIST_PAGE_REDIRECT = 'index.customercare.customer.customerProducts.list';
export const SERVICES_USAGE_PAGE_REDIRECT = 'index.customercare.customer.servicesAndUsage';
export const MAKE_PAYMENT_PAGE_REDIRECT = 'index.customercare.customer.makePayment';
export const AUTOPAY_PAGE_REDIRECT = 'index.customercare.customer.autoPay';
export const TREATMENT_DETAILS_PAGE_REDIRECT = 'index.customercare.customer.treatmentDetails';

const MODIFY_FDO_ACTION_TYPE = 'modify-future-dated-order';

export function getReactRoutes({isNonTelcoUsageServicePageEnabled,
    isProductBetaEnabled,
    isServicesAndUsageBetaEnabled,
    isMakePaymentBetaEnabled,
    isTreatmentBetaEnabled,
    isReactDashboardEnabled,
    isReactProductOrderEnabled}) {
    return {
        ...(isReactDashboardEnabled ? {
            [DASHBOARD_ROUTE]: {
                url:`${CUSTOMER}/dashboard`
            }
        } : {}),
        [ACCOUNT_HIERARCHY_STATE_REDIRECT]: {
            url: `${CUSTOMER}/account-hierarchy`
        },
        [ACCOUNT_HIERARCHY_NODE_SERVICES_PAGE_REDIRECT]: {
            url: `${CUSTOMER}/account-hierarchy/:hierarchyId/node/:hierarchyNodeSubscriberId/services`
        },
        [ADDRESSES_STATE_REDIRECT]: {
            url: `${CUSTOMER}/addresses`
        },
        [CASES_LIST_STATE_REDIRECT]: {
            url: `${CUSTOMER}/cases`
        },
        [CASES_DETAILS_STATE_REDIRECT]: {
            url: `${CUSTOMER}/cases/:caseId`
        },
        [E_WALLET_STATE_REDIRECT]: {
            url: `${CUSTOMER}/e-wallet`
        },
        [INVOICE_MESSAGES_LIST_STATE_REDIRECT]: {
            url: `${CUSTOMER}/billing/invoice-messages`
        },
        [INVENTORY_LIST_STATE_REDIRECT]: {
            url: `${CUSTOMER}/inventory`
        },
        [INVENTORY_DETAILS_STATE_REDIRECT]: {
            url: `${CUSTOMER}/inventory/:inventoryId`
        },
        [INTERACTIONS_LIST_STATE_REDIRECT]: {
            url: `${CUSTOMER}/interactions`
        },
        [INTERACTIONS_DETAILS_STATE_REDIRECT]: {
            url: `${CUSTOMER}/interactions/:interactionId`
        },
        [ADD_OFFERING_INSTANCES]: {
            url: `${CUSTOMER}/ordering/add-offering-instances/`,
            queryBuilder: (params) => {
                return params.futureDatedOrderId ? {
                    orderActionType: MODIFY_FDO_ACTION_TYPE,
                    futureDatedOrderId: params.futureDatedOrderId,
                    futureDatedOfferingId: params.futureDatedOfferingId
                } : {};
            }
        },
        [EDIT_OFFERING_INSTANCES]: {
            url: `${CUSTOMER}/ordering/edit-offering-instances/:offeringId/:offeringInstanceId`,
            queryBuilder: (params) => {
                return params.futureDatedOrderId ? {
                    orderActionType: MODIFY_FDO_ACTION_TYPE,
                    futureDatedOrderId: params.futureDatedOrderId
                } : {};
            }
        },
        [REVIEW_SAVED_CART_INSTANCES]: {
            url: `${CUSTOMER}/ordering/add-offering-instances?orderActionType=review-saved-cart`
        },
        [NEW_CONNECT_PAGE]: {
            url: `${HOME}new-offering`
        },
        [ADD_OFFERING_PAGE]: {
            url: `${CUSTOMER}/ordering/add-offering`,
            queryBuilder: (params) => {
                return params.futureDatedOrderId ? {
                    orderActionType: MODIFY_FDO_ACTION_TYPE,
                    futureDatedOrderId: params.futureDatedOrderId,
                    futureDatedOfferingId: params.futureDatedOfferingId
                } : {};
            }
        },
        [REVIEW_SAVED_CART]: {
            url: `${CUSTOMER}/ordering/add-offering?orderActionType=review-saved-cart`
        },
        [REVIEW_STORE_ORDER_ADD]: {
            url: `${CUSTOMER}/ordering/add-offering/checkout/resume-store-order`
        },
        [EDIT_OFFERING_PAGE]: {
            url: `${CUSTOMER}/ordering/edit-offering/:offeringId/:offeringInstanceId`,
            queryBuilder: (params) => {
                return params.futureDatedOrderId ? {
                    orderActionType: MODIFY_FDO_ACTION_TYPE,
                    futureDatedOrderId: params.futureDatedOrderId
                }: {};
            }
        },
        [REVIEW_STORE_ORDER_EDIT]: {
            url: `${CUSTOMER}/ordering/edit-offering/:offeringId/:offeringInstanceId/checkout/resume-store-order`
        },
        [REVIEW_STORE_ORDER_TRANSITION]: {
            url: `${CUSTOMER}/ordering/change-offering/:offeringId/:offeringInstanceId/checkout/resume-store-order`
        },
        [CHANGE_OFFERING_PAGE]: {
            url: `${CUSTOMER}/ordering/change-offering/:offeringId/:offeringInstanceId`
        },
        [DISCONNECT_OFFERS_DBSS_ROUTE]: {
            url: `${CUSTOMER}/ordering/remove-offering/:offeringId/:offeringInstanceId`
        },
        [TRANSFER_STATE_NAME]: {
            url:`${CUSTOMER}/ordering/transfer-offerings`
        },
        [NOTIFICATIONS_LIST_STATE_REDIRECT]: {
            url: `${CUSTOMER}/notifications`
        },
        [GROUP_ORDER_HISTORY_LIST_STATE_NAME]: {
            url: `${CUSTOMER}/order-history/group-orders`
        },
        [RATE_SHEET_STATE_REDIRECT]: {
            url: `${CUSTOMER}/rate-sheet`
        },
        [PRICING_PLAN_EARLY_RENEWAL_REDIRECT]: {
            url: `${ORDERING}/plan/early-renewal/:offeringInstanceId/:subscriberProductId?`
        },
        [DBSS_PRODUCT_DETAILS_PAGE_REDIRECT]: {
            url: `${CUSTOMER}/products/details/:subscriberProductId`
        },
        [SUSPEND_SUBSCRIBER_PRODUCTS_REDIRECT]: {
            url: `${CUSTOMER}/ordering/suspend-subscriber-products/:offeringId/:offeringInstanceId`
        },
        [RESUME_SUBSCRIBER_PRODUCTS_REDIRECT]: {
            url: `${CUSTOMER}/ordering/restore-subscriber-products/:offeringId/:offeringInstanceId`
        },
        [SUSPEND_SERVICES_REDIRECT]: {
            url: `${CUSTOMER}/ordering/suspend-services/:serviceIdentifier?`
        },
        [RESUME_SERVICES_REDIRECT]: {
            url: `${CUSTOMER}/ordering/restore-services/:serviceIdentifier?`
        },
        [RECONNECT_OFFER_STATE_NAME]: {
            url: `${CUSTOMER}/ordering/edit-offering/:offeringId/:offeringInstanceId/decisions/reconnect-offer-flow`
        },
        [DISCONNECT_CUSTOMER_DBSS_ROUTE]: {
            url: `${CUSTOMER}/ordering/disconnect`
        },
        [E_WALLET_PAGE]: {
            url:`${CUSTOMER}/e-wallet`
        },
        ...(isProductBetaEnabled ? {
            [PRODUCT_LIST_PAGE_REDIRECT]: {
                url:`${CUSTOMER}/products/list`
            }
        } : {}),
        ...(isProductBetaEnabled ? {
            [PRODUCT_DETAILS_PAGE_REDIRECT]: {
                url: `${CUSTOMER}/products/details/:lockerItemId`
            }
        } : {}),
        ...(isTreatmentBetaEnabled ? {
            [TREATMENT_DETAILS_PAGE_REDIRECT]: {
                url:`${CUSTOMER}/treatment-details`
            }
        } : {}),
        [OFFERINGS_STATE_NAME]: {
            url:`${CUSTOMER}/offerings/:offeringInstanceId?`
        },
        [SERVICE_DETAILS_STATE_NAME]: {
            url:`${SERVICES}/:serviceIdentifier`
        },
        ...(isServicesAndUsageBetaEnabled ? {
            [SERVICES_USAGE_PAGE_REDIRECT]: {
                url:`${CUSTOMER}/services-and-usages/services`
            }
        } : {}),
        ...(isNonTelcoUsageServicePageEnabled ? {
            [USAGE_DETAILS_NON_TELCO_SERVICES_REDIRECT]: {
                url: `${SERVICES_AND_USAGES}/usage-details-finance`,
                queryBuilder: (params) => {
                    return {
                        serviceIdentifierValue: params.serviceIdentifierValue
                    };
                }
            }
        } : {}),
        [SERVICE_ADD_ON_PRODUCTS_ORDERING_PAGE_ROUTE]: {
            url: `${CUSTOMER}/ordering/service-feature-products/:serviceIdentifierValue/:serviceId/service-add-ons/:serviceAttributeId`
        },
        [INVENTORY_ADD_ON_PRODUCTS_ORDERING_PAGE_ROUTE]: {
            url: `${CUSTOMER}/ordering/service-feature-products/:serviceIdentifierValue/:serviceId/inventory-add-ons/:inventoryDisplayName`
        },
        ...(isMakePaymentBetaEnabled ? {
            [MAKE_PAYMENT_PAGE_REDIRECT]: {
                url: `${CUSTOMER}/make-payment/:account?`,
                params: (params) => {
                    return {
                        ...(params?.account ? {
                            account: params?.account
                        }: {}),
                        customerId: params?.customerId
                    };
                },
                queryBuilder: (params) => {
                    return {
                        ...(params?.invoiceNumber ? {
                            invoiceNumber: params.invoiceNumber
                        } : {}),
                        ...(params.paymentType ? {
                            paymentType: params.paymentType
                        } : {})
                    };
                }
            }
        } : {}),
        ...(isReactProductOrderEnabled ? {
            [CREATE_PRODUCTS_ORDER_STATE_NAME]: {
                url: `${CUSTOMER}/ordering/create-product-order`
            }
        } : {})
    };
};