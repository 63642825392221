import Immutable from 'seamless-immutable';
import {
    compose,
    curry,
    findIndex,
    isNil,
    path,
    propEq
} from 'ramda';
import {
    ADD_UNSAVED_PAYMENT_INSTRUMENT,
    CHARGE_INSTRUMENT,
    CLEAR_SELECTED_PAYMENT_METHOD,
    CLEAR_EWALLET_ERROR,
    CLEAR_EWALLET_RETRIEVAL_ERROR,
    CREATE_PAYMENT_INSTRUMENT,
    EDIT_PAYMENT_INSTRUMENT,
    EDIT_PAYMENT_INSTRUMENT_AUTO_PAY,
    EMPTY_EWALLET_LIST,
    CLEAR_IS_MAKING_PAYMENT,
    REDEEM_GIFT_CARD,
    REMOVE_PAYMENT_INSTRUMENT,
    RENEW_OFF_CYCLE_CHARGES,
    REPLACE_WALLET_INSTRUMENTS,
    RETRIEVE_AVAILABLE_PAYMENT_INSTRUMENT_TYPES,
    RETRIEVE_PAYMENT_INSTRUMENT,
    RETRIEVE_WALLET,
    RETRIEVE_WALLET_COMPLETED,
    SAVE_AUTO_PAY_FLAG_STATUS,
    SELECT_PAYMENT_METHOD,
    SET_EWALLET_RETRIEVAL_ERROR,
    SET_RECORD_PAYMENT_INSTRUMENT,
    SUPPLY_PAYMENT_INSTRUMENT,
    UPDATE_PAYMENT_ACCOUNTS_ON_SELECTED_PAYMENT_METHOD
} from './actions/customer.ewallet.actions';
import {ADD_TO_BLOCKLIST,
    ADD_TO_ALLOWLIST,
    REMOVE_FROM_BLOCKLIST,
    REMOVE_FROM_ALLOWLIST} from './actions/blocklist.allowlist.actions';
import {BEGIN_NEW_CONNECT_ORDER} from './actions/new.connect.wizard.actions';
import {BEGIN_EDIT_OFFER_ORDER,
    CANCEL_ORDER as EDIT_CANCEL_ORDER,
    EDIT_SUBMIT_COS_ORDER} from './actions/edit.offer.wizard.actions';
import {BEGIN_ADD_OFFER_ORDER,
    CANCEL_ORDER as ADD_CANCEL_ORDER} from './actions/add.offer.wizard.actions';
import {SUBMIT_ORDER} from './actions/offering.order.actions';
import blocklistAllowlistReducer, {INITIAL_STATE as BW_REASON_INITIAL_STATE} from '../reducers/blocklist.allowlist.reducer';
import * as EWalletReducerHelper from './helpers/ewallet.reducer.helper';

export const INITIAL_STATE = Immutable({
    convergentBillerAccounts: {},
    data: {
        availableTypesMap: null,
        blocklistAllowlist: BW_REASON_INITIAL_STATE,
        paymentInstruments: [],
        paymentInstrumentDetail: {},
        paymentIsInvalid: true,
        selectedPaymentInstrument: null
    },
    isAutoPayActive: false,
    isCreatingOrEditingData: false,
    isFetchingData: false,
    isMakingPayment: false,
    isRemovingPaymentInstrument: false,
    isRetrievingWallet: false,
    isShowingPaymentInstrumentPanel: false,
    isRenewingOffCycleCharges: false,
    isUpdatingAutoPayConfiguration: false,
    eWalletError: null,
    eWalletRetrievalError: null,
    recordPaymentInfo: true,
    supplyPaymentInstrument: false
});

export default function reducer(state = INITIAL_STATE, action) {
    return compose(
        setBlocklistAllowlistState(action),
        setEwalletState(action))(state);
}

function ewalletReducer(state = INITIAL_STATE, {payload, type}) {
    switch (type) {
        case ADD_CANCEL_ORDER:
        case EDIT_CANCEL_ORDER:
        case BEGIN_ADD_OFFER_ORDER:
        case BEGIN_EDIT_OFFER_ORDER:
        case BEGIN_NEW_CONNECT_ORDER:
        case EDIT_SUBMIT_COS_ORDER.SUCCESS:
        case EMPTY_EWALLET_LIST:
        case SUBMIT_ORDER.SUCCESS:
            return INITIAL_STATE;
        case CHARGE_INSTRUMENT.BEGIN:
            return state
                .set('isFetchingData', true)
                .set('isMakingPayment', true);
        case CHARGE_INSTRUMENT.SUCCESS:
            return state
                .set('isFetchingData', false)
                .setIn(['data', 'paymentIsInvalid'], false)
                .set('eWalletError', null);
        case CHARGE_INSTRUMENT.FAILURE:
            return state
                .set('isFetchingData', false)
                .setIn(['data', 'paymentIsInvalid'], true)
                .set('eWalletError', {
                    code: payload.Code,
                    message: payload.translatedMessage,
                    severity: payload.Severity
                });
        case CLEAR_SELECTED_PAYMENT_METHOD:
            return state
                .setIn(['data', 'selectedPaymentInstrument'], null);
        case EDIT_PAYMENT_INSTRUMENT_AUTO_PAY.BEGIN:
            return state.set('isCreatingOrEditingData', true)
                .set('isUpdatingAutoPayConfiguration', true)
                .set('eWalletError', null);
        case CREATE_PAYMENT_INSTRUMENT.BEGIN:
        case EDIT_PAYMENT_INSTRUMENT.BEGIN:
        case REDEEM_GIFT_CARD.BEGIN:
            return state.set('isCreatingOrEditingData', true)
                .set('eWalletError', null);
        case CREATE_PAYMENT_INSTRUMENT.SUCCESS:
        case EDIT_PAYMENT_INSTRUMENT.SUCCESS:
        case EDIT_PAYMENT_INSTRUMENT_AUTO_PAY.SUCCESS:
            return state
                .setIn(['data', 'selectedPaymentInstrument'], isNil(payload) ? null : payload.PaymentInstrument)
                .setIn(['data', 'paymentInstruments'], updatePaymentList(state, payload))
                .set('isCreatingOrEditingData', false)
                .set('isUpdatingAutoPayConfiguration', false)
                .set('eWalletError', null);
        case REDEEM_GIFT_CARD.SUCCESS:
            return state
                .setIn(['data', 'selectedPaymentInstrument'], isNil(payload) ? null : payload.GiftCard)
                .set('isCreatingOrEditingData', false)
                .set('isUpdatingAutoPayConfiguration', false)
                .set('eWalletError', null);
        case CREATE_PAYMENT_INSTRUMENT.FAILURE:
        case EDIT_PAYMENT_INSTRUMENT.FAILURE:
        case EDIT_PAYMENT_INSTRUMENT_AUTO_PAY.FAILURE:
        case REDEEM_GIFT_CARD.FAILURE:
            return state
                .set('isCreatingOrEditingData', false)
                .set('isUpdatingAutoPayConfiguration', false)
                .set('eWalletError', {
                    code: payload.Code,
                    message: payload.translatedMessage,
                    severity: payload.Severity
                });
        case CLEAR_EWALLET_ERROR:
            return state.set('eWalletError', null);
        case CLEAR_IS_MAKING_PAYMENT:
            return state.set('isMakingPayment', false);
        case UPDATE_PAYMENT_ACCOUNTS_ON_SELECTED_PAYMENT_METHOD:
            return state.setIn(['data', 'paymentInstruments'], updateConvergentBillerPaymentInstrumentAccounts(state.data.paymentInstruments, state.data.selectedPaymentInstrument && state.data.selectedPaymentInstrument.Id, payload.ConvergentBillerPaymentInstrumentAccounts));
        case REMOVE_PAYMENT_INSTRUMENT.BEGIN:
            return state
                .set('isRemovingPaymentInstrument', true)
                .set('eWalletError', null);
        case SELECT_PAYMENT_METHOD:
            return state.setIn(['data', 'selectedPaymentInstrument'], payload);
        case RENEW_OFF_CYCLE_CHARGES.BEGIN:
            return state.set('isRenewingOffCycleCharges', true);
        case RENEW_OFF_CYCLE_CHARGES.SUCCESS:
        case RENEW_OFF_CYCLE_CHARGES.FAILURE:
            return state.set('isRenewingOffCycleCharges', false);
        case REMOVE_PAYMENT_INSTRUMENT.SUCCESS:
            return state
                .setIn(['data', 'selectedPaymentInstrument'], null)
                .set('isRemovingPaymentInstrument', false)
                .set('eWalletError', null);
        case REMOVE_PAYMENT_INSTRUMENT.FAILURE:
            return state
                .set('isRemovingPaymentInstrument', false)
                .set('eWalletError', {
                    code: payload.Code,
                    message: payload.translatedMessage,
                    severity: payload.Severity
                });
        case REPLACE_WALLET_INSTRUMENTS:
            return state.setIn(['data', 'paymentInstruments'], payload);
        case RETRIEVE_PAYMENT_INSTRUMENT.BEGIN:
            return state.set('isFetchingData', true);
        case RETRIEVE_PAYMENT_INSTRUMENT.SUCCESS: {
            const paymentInstrument = EWalletReducerHelper.fixPaymentInstrumentData(payload.PaymentInstrument);
            if (path(['data', 'selectedPaymentMethod', 'Id'], state) === paymentInstrument.Id) {
                return state
                    .setIn(['data', 'selectedPaymentMethod'], Object.assign({}, state.data.selectedPaymentMethod, paymentInstrument))
                    .setIn(['data', 'paymentInstrumentDetail'], paymentInstrument)
                    .set('isFetchingData', false);
            } else {
                return state
                    .setIn(['data', 'paymentInstrumentDetail'], paymentInstrument)
                    .set('isFetchingData', false);
            }
        }
        case RETRIEVE_PAYMENT_INSTRUMENT.FAILURE:
            return state
                .set('isFetchingData', false)
                .set('eWalletError', {
                    code: payload.Code,
                    message: payload.translatedMessage,
                    severity: payload.Severity
                });
        case RETRIEVE_WALLET.BEGIN:
            return state
                .set('isFetchingData', true)
                .set('isRetrievingWallet', true);
        case RETRIEVE_WALLET.SUCCESS:
            return state
                .set('isFetchingData', false)
                .set('eWalletError', null);
        case RETRIEVE_WALLET.FAILURE:
            return state
                .set('isFetchingData', false)
                .set('eWalletError', {
                    code: payload.Code,
                    message: payload.translatedMessage,
                    severity: payload.Severity
                })
                .set('eWalletRetrievalError', {
                    code: payload.Code,
                    message: payload.translatedMessage,
                    severity: payload.Severity
                });
        case RETRIEVE_AVAILABLE_PAYMENT_INSTRUMENT_TYPES.BEGIN:
            return state.set('isFetchingData', true);
        case RETRIEVE_AVAILABLE_PAYMENT_INSTRUMENT_TYPES.SUCCESS:
            return state
                .set('isFetchingData', false)
                .setIn(['data', 'availableTypesMap'], populateAvailableTypesMap(payload));
        case RETRIEVE_AVAILABLE_PAYMENT_INSTRUMENT_TYPES.FAILURE:
            return state.set('isFetchingData', false);
        case RETRIEVE_WALLET_COMPLETED:
            return state.set('isRetrievingWallet', false);
        case CLEAR_EWALLET_RETRIEVAL_ERROR:
            return state.set('eWalletRetrievalError', null);
        case SAVE_AUTO_PAY_FLAG_STATUS:
            return state.set('isAutoPayActive', payload);
        case SET_EWALLET_RETRIEVAL_ERROR:
            return state
                .set('eWalletError', {
                    code: payload.Code,
                    message: payload.translatedMessage,
                    severity: payload.Severity
                })
                .set('eWalletRetrievalError', {
                    code: payload.Code,
                    message: payload.translatedMessage,
                    severity: payload.Severity
                });
        case SUPPLY_PAYMENT_INSTRUMENT:
            return state.set(['supplyPaymentInstrument'], payload);
        case SET_RECORD_PAYMENT_INSTRUMENT:
            return state.set(['recordPaymentInfo'], payload);
        case ADD_TO_BLOCKLIST.FAILURE:
        case ADD_TO_ALLOWLIST.FAILURE:
        case REMOVE_FROM_BLOCKLIST.FAILURE:
        case REMOVE_FROM_ALLOWLIST.FAILURE:
            return state.set('eWalletError', {
                code: payload.Code,
                message: payload.translatedMessage,
                severity: payload.Severity
            });
        case ADD_UNSAVED_PAYMENT_INSTRUMENT:
            return state
                .setIn(['data', 'selectedPaymentInstrument'], isNil(payload) ? null : payload.PaymentInstrument)
                .setIn(['data', 'paymentInstruments'], updatePaymentList(state, payload));
        default:
            return state;
    }
}

function updateConvergentBillerPaymentInstrumentAccounts(paymentInstruments, paymentInstrumentId, convergentBillerPaymentInstrumentAccounts) {
    const updatedPaymentInstruments = paymentInstruments.map((paymentInstrument) => {
        if (paymentInstrument.Id !== paymentInstrumentId) {
            return paymentInstrument;
        } else {
            return Object.assign({}, paymentInstrument, {
                ConvergentBillerPaymentInstrumentAccounts: convergentBillerPaymentInstrumentAccounts
            });
        }
    });
    return updatedPaymentInstruments;
}

function updatePaymentList(state, payload) {
    if (!state.data.paymentInstruments) {
        return undefined;
    }

    const paymentList = state.data.paymentInstruments.asMutable({
        deep: true
    });

    if (!payload || !payload.PaymentInstrument) {
        return paymentList;
    }

    const paymentInstrument = payload.PaymentInstrument;

    const index = findIndex(propEq(paymentInstrument.Id, 'Id'))(paymentList);

    if (paymentInstrument.Default) {
        paymentList.map((payment) => {
            payment.Default = false;
        });
    }

    if (index > -1) {
        paymentList[index] = paymentInstrument;
    } else {
        paymentList.push(paymentInstrument);
    }

    return paymentList;
}

function populateAvailableTypesMap(response) {
    const availableTypesMap = {};
    if (response.AvailablePaymentInstrumentTypes) {
        response.AvailablePaymentInstrumentTypes.forEach(availableType => {
            availableTypesMap[availableType.Type] = availableType;
        });
    }
    return availableTypesMap;
}

const setEwalletState = curry((action, state) => {
    return ewalletReducer(state, action);
});

const curriedSetState = curry((path, childReducer, accessorFunc, action, parentState) => {
    return parentState.setIn(path, childReducer(accessorFunc(parentState), action));
});

const setBlocklistAllowlistState = curriedSetState(
    ['data', 'blocklistAllowlist'],
    blocklistAllowlistReducer,
    state => {
        return state.data.blocklistAllowlist;
    }
);
