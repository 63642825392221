import {
    stateGo,
    stateReload
} from 'redux-ui-router';
import {
    NewWindow,
    SubscriberOrderTypeEnum
} from 'invision-core';
import forEachObjIndexed from 'ramda/src/forEachObjIndexed';
import isNil from 'ramda/src/isNil';
import pathOr from 'ramda/src/pathOr';
import i18n from 'invision-core/src/components/i18n/i18n';
import {
    fetchCodeTypes,
    fetchServiceAttributes
} from 'invision-core/src/components/metadata/codes/codes.actions';
import {CODES} from 'invision-core/src/components/metadata/codes/codes.constants';
import {IsDbss} from 'invision-core/src/components/session/businessunit.selectors';
import {
    MetadataCodeLoadedSelector,
    MetadataCodeTypeSelector
} from 'invision-core/src/components/metadata/codes/codes.selectors';
import {UserSecurityAttributesSelector} from 'invision-core/src/components/session/session.selectors';
import {OfferingMetadataByIdSelector} from 'invision-core/src/components/metadata/offerings/offerings.selectors';
import {
    hasAccess,
    hasReadAccess
} from 'invision-core/src/components/security/permission.service';
import {retrieveOfferingsMetadata} from 'invision-core/src/components/metadata/offerings/offerings.actions';

import CustomerLocaleKeys from '../../../../locales/keys';
import {OVERRIDE_BILLING_EFFECTIVE_DATE, PAYMENT_ACTIVITY_VIEW_ACCESS} from '../../../../security.attributes';
import {NOTIFICATION_TIME_LENGTH} from '../../../../customercare.constants';
import {
    CurrentOrderIdSelector,
    CurrentCustomerIdSelector,
    RouteParams,
    RoutePreviousState,
    PreviousSubscriptionSelector
} from '../../../../reducers/selectors/customer.selectors';
import {WorkflowActivityItemsSelector} from '../../../../reducers/selectors/workflow.selectors';
import {
    AdditionalAttributesForOrder,
    ConvergentBillerOrderDetailsSelector,
    ConvergentBillerOrderPaymentInstruments,
    ConvergentBillerOrderProductIdsSelector,
    ConvergentBillerOrderServiceDetailsFormattedSelector,
    ConvergentBillerOrderTransferredSubscriberSelector,
    CurrentOrderDetailsPaymentInstrumentSelector,
    CurrentOrderItemDisplayItemsSelector,
    CurrentOrderItemsBulkStatusViewModelSelector,
    CurrentOrderItemTransactionSelector,
    HasOrderDetailTotalsSelector,
    IsFetchingOrderDetailsSelector,
    IsFetchingOrderServiceDetailsSelector,
    ORDER_DETAILS_MORE_MENU_CONSTANTS,
    OrderContractDetailsSelector,
    OrderContractExternalContractIdSelector,
    OrderDetailsMoreMenuItemsSelector,
    OrderDetailsPurchaseOrderNumberSelector,
    OrderItemToDisplayServiceAttributesFor,
    OrderServiceDetailsErrorSelector,
    OrderStoresSelector,
    OrderWorkflowSummarySelector
} from '../../../../reducers/selectors/customer.convergent.biller.selectors';
import {
    BillingEffectiveDateOptionsSelector,
    BillingEffectiveDateSettingsSelector,
    CanIssueOrderCreditSelector,
    CurrentOrderBillingEffectiveDateSelector,
    CurrentOrderHasShippingAddressSelector,
    FormattedShippingCityStateSelector,
    IsDbssOrderSelector,
    IsGiftRedemptionOrderSelector,
    IsRetrievingOrderContextSelector,
    OrderDetailsImportServiceAttributesJobsPendingUpdatedStatusSelector,
    OrderDetailTabsSelector,
    SelectedOfferExecutionOptionsSelector,
    ShowOrderDetailsExecutionDate
} from '../../../../reducers/selectors/order.details.selectors';
import {
    CurrentDetailsGiftOrderInfoSelector,
    IsResendingGiftNotificationSelector,
    ResendGiftNotificationErrorSelector,
    ResendGiftNotificationInfoSelector
} from '../../../../reducers/selectors/resend.gift.notification.selectors';
import {
    retrieveConvergentBillerOrderDetails,
    setOrderItemToDisplayServiceAttributesFor
} from '../../../../reducers/actions/customer.convergent.biller.actions';
import {updateLifeCycleDetails} from '../../../../reducers/actions/customer.actions';
import {
    cancelOrder,
    cancelPendingOrder,
    completeOrder,
    resubmitOrder,
    retrieveOrderServiceDetails,
    searchSubscriberOfferings
} from '../../../../reducers/actions/order.actions';
import {
    CancelOrderErrorSelector,
    IsCancellingOrderSelector
} from '../../../../reducers/selectors/order.history.selectors';
import {getWorkflowActivityInstance} from '../../../../reducers/actions/workflow.actions';
import {
    clearImportBulkServiceAttributesJobsPendingUpdatedStatus,
    retrieveOrderContext,
    setCurrentTab,
    updateFdoExecutionDate,
    updateImportBulkServiceAttributesJobsPendingUpdatedStatus
} from '../../../../reducers/actions/order.details.actions';
import {
    resetGiftOrderInfo,
    setResendGiftOrderInfo,
    submitResendGiftNotification
} from '../../../../reducers/actions/resend.gift.notification.actions';
import {
    ACTIONS_ITEMS,
    IMPORT_BULK_SERVICE_ATTRIBUTES_JOB_PENDING_STATUSES,
    IMPORT_BULK_SERVICE_ATTRIBUTES_JOB_STATUSES,
    ORDER_STATUS,
    ORDER_TYPES
} from './order.details.constants';
import {TRANSACTION_DETAILS_STATE} from '../../transactions/transactions.config';
import {GRANTED_COUPON_ROUTE, COUPON_DETAILS_ROUTE} from '../../coupons/customer.coupons.config';
import {COUPON_DETAILS_GLOBAL_ROUTE} from '../../../couponRedemptionCode/coupon.redemption.code.config';
import {SUBSCRIPTION_DETAILS_ROUTE} from '../../subscriptions/subscriptions.config';
import {DETAIL_STATE_OR_NAME as PRODUCT_STATE} from '../../products/products.config';
import {getProductMetadataBatch} from '../../../../reducers/actions/products.order.actions';
import {BulkChargeTypes} from '../../../wizards/newConnectWizard/new.connect.wizard.constants';
import {returnExpiredImportBulkServiceAttributesJobsPendingUpdatedStatus} from './importBulkAttributesModal/import.bulk.attributes.modal.helper';
import {
    IsFutureDatedOrderAllowedInBunt,
    LifeCyclePricingPlanDetailsSelector,
    PricingPlanInLifeCycleForOffersSelector
} from '../../../../reducers/selectors/selected.offering.order.selectors';
import {retrieveCandidateBillCycles} from '../../../../reducers/actions/customer.billcycle.actions';
import {DisconnectReasonForOrderDetailsSelector} from '../../../../reducers/selectors/services.selectors';
import {
    SERVICES_AND_SHARED_ENTITLEMENTS,
    SERVICES_AND_SHARED_ENTITLEMENTS_TAB
} from '../../servicesAndUsage/services.and.usage.config';
import {CurrentCustomerNodeSelector} from '../../../../reducers/selectors/customer.account.hierarchy.selectors';
import {
    EDIT_OFFERING_INSTANCES,
    STATE_OR_NAME as EDIT_OFFERING
} from '../../../../reducers/constants/edit.offer.wizard.constants';
import {
    ADD_OFFERING_INSTANCES,
    STATE_OR_NAME as ADD_OFFERING
} from '../../../../reducers/constants/add.offer.wizard.constants';
import {OFFERING_STATUS_TYPES} from '../../offerings/offerings.constants';

class OrderDetailsController {
    constructor($ngRedux, $timeout, uiNotificationService, $filter) {
        Object.assign(this, {
            $filter,
            $ngRedux,
            $timeout,
            onCloseAppointmentDetailsModal: this.onCloseAppointmentDetailsModal.bind(this),
            orderItemsWithAppointments: [],
            currentDiscountDetails: null,
            CustomerLocaleKeys,
            onBannerClose: this.onBannerClose.bind(this),
            ORDER_STATUS,
            ORDER_TYPES,
            showDiscountDetailsPopup: false,
            showAppointmentDetails: false,
            showAppointmentDetailsModal: false,
            uiNotificationService
        });
    }
    $onInit() {
        const mapStateToTarget = (store) => {
            return {
                additionalProperties: AdditionalAttributesForOrder(store),
                areBulkTypesLoaded: MetadataCodeLoadedSelector(CODES.BulkChargeType, store),
                arePeriodTypesLoaded: MetadataCodeLoadedSelector(CODES.PeriodType, store),
                billingEffectiveDateOptions: BillingEffectiveDateOptionsSelector(store),
                billingEffectiveDateSettings: BillingEffectiveDateSettingsSelector(store),
                bulkServiceAttributesJobsPendingUpdatedStatus: OrderDetailsImportServiceAttributesJobsPendingUpdatedStatusSelector(store),
                cancelOrderErrorMessage: CancelOrderErrorSelector(store),
                canIssueOrderCredit: CanIssueOrderCreditSelector(store),
                currentOrderDetailsPaymentInstrumentSelector: CurrentOrderDetailsPaymentInstrumentSelector(store),
                currentCustomerId: CurrentCustomerIdSelector(store),
                currentCustomerNode: CurrentCustomerNodeSelector(store),
                currentOrderBillingEffectiveDate: CurrentOrderBillingEffectiveDateSelector(store),
                currentOrderDetails: ConvergentBillerOrderDetailsSelector(store),
                currentOrderDisplayItems: CurrentOrderItemDisplayItemsSelector(store),
                currentOrderGiftOrderInfo: CurrentDetailsGiftOrderInfoSelector(store),
                currentOrderHasShippingAddress: CurrentOrderHasShippingAddressSelector(store),
                currentOrderId: CurrentOrderIdSelector(store),
                currentOrderItemsBulkStatusesViewModel: CurrentOrderItemsBulkStatusViewModelSelector(store),
                currentOrderPaymentInstruments: ConvergentBillerOrderPaymentInstruments(store),
                currentOrderProductIds: ConvergentBillerOrderProductIdsSelector(store),
                currentOrderServiceDetails: ConvergentBillerOrderServiceDetailsFormattedSelector(store),
                currentOrderTransaction: CurrentOrderItemTransactionSelector(store),
                currentOrderTransferredSubscriber: ConvergentBillerOrderTransferredSubscriberSelector(store),
                disconnectReasonForOrderDetails: DisconnectReasonForOrderDetailsSelector(store),
                executionOptions: SelectedOfferExecutionOptionsSelector(store),
                fdoAllowedInBunt: IsFutureDatedOrderAllowedInBunt(store),
                fdoConfigurationLoaded: MetadataCodeLoadedSelector(CODES.FutureDatedOrderConfiguration, store),
                formattedShippingCityState: FormattedShippingCityStateSelector(store),
                hasOrderDetailTotals: HasOrderDetailTotalsSelector(store),
                isCancellingOrder: IsCancellingOrderSelector(store),
                isDbss: IsDbss(store),
                isDbssOrder: IsDbssOrderSelector(store),
                isFetchingOrderDetails: IsFetchingOrderDetailsSelector(store),
                isFetchingOrderServiceDetails: IsFetchingOrderServiceDetailsSelector(store),
                isGiftRedemptionOrder: IsGiftRedemptionOrderSelector(store),
                isResendingGiftNotification:IsResendingGiftNotificationSelector(store),
                isRetrievingOrderContext: IsRetrievingOrderContextSelector(store),
                isServiceReasonCodeLoaded: MetadataCodeLoadedSelector(CODES.ReasonCodes, store),
                isOrderItemChangeLoaded: MetadataCodeLoadedSelector(CODES.OrderItemChangeType, store),
                lifeCyclePricingPlanDetails: LifeCyclePricingPlanDetailsSelector(store),
                moreMenuItems: OrderDetailsMoreMenuItemsSelector(store),
                offeringMetadataById: OfferingMetadataByIdSelector(store),
                orderContractDetails: OrderContractDetailsSelector(store),
                orderContractExternalContractId: OrderContractExternalContractIdSelector(store),
                orderItemToDisplayServiceAttributesFor: OrderItemToDisplayServiceAttributesFor(store),
                orderServiceDetailsError: OrderServiceDetailsErrorSelector(store),
                orderStores: OrderStoresSelector(store),
                previousPageSubscription: PreviousSubscriptionSelector(store),
                pricingPlanInLifeCycleForOffers: PricingPlanInLifeCycleForOffersSelector(store),
                purchaseOrderNumber: OrderDetailsPurchaseOrderNumberSelector(store),
                regexCodes: MetadataCodeTypeSelector(CODES.RegularExpression, store),
                regexCodesLoaded: MetadataCodeLoadedSelector(CODES.RegularExpression, store),
                resendGiftNotificationError: ResendGiftNotificationErrorSelector(store),
                resendGiftNotificationInfo: ResendGiftNotificationInfoSelector(store),
                routeParams: RouteParams(store),
                routePreviousState: RoutePreviousState(store),
                serviceAtrributesLoaded: MetadataCodeLoadedSelector(CODES.ServiceAttribute, store),
                serviceAttributeCodes: MetadataCodeTypeSelector(CODES.ServiceAttribute, store),
                showExecutionDate: ShowOrderDetailsExecutionDate(store),
                storesLoaded: MetadataCodeLoadedSelector(CODES.Stores, store),
                tabs: OrderDetailTabsSelector(store),
                userSecurityAttributes: UserSecurityAttributesSelector(store),
                workflowActivities: WorkflowActivityItemsSelector(store),
                workflows: OrderWorkflowSummarySelector(store)
            };
        };
        const controllerActions = {
            cancelOrder,
            cancelPendingOrder,
            clearImportBulkServiceAttributesJobsPendingUpdatedStatus,
            completeOrder,
            fetchBulkChargeTypes: () => {
                return fetchCodeTypes(CODES.BulkChargeType);
            },
            fetchCodeTypes,
            fetchDisconnectReasons: () => {
                return fetchCodeTypes(CODES.ReasonCodes);
            },
            fetchOrderItemChangeType: () => {
                return fetchCodeTypes(CODES.OrderItemChangeType);
            },
            fetchFdoConfiguration: () => {
                return fetchCodeTypes(CODES.FutureDatedOrderConfiguration);
            },
            fetchPeriodTypes: () => {
                return fetchCodeTypes(CODES.PeriodType);
            },
            fetchServiceAttributes,
            fetchWorkflowActivityStates: () => {
                return fetchCodeTypes(CODES.WorkflowActivityState);
            },
            fetchObriConfig: () => {
                return fetchCodeTypes(CODES.OneTimeBillerRuleConfiguration);
            },
            fetchRbriConfig: () => {
                return fetchCodeTypes(CODES.RecurringBillerRuleConfiguration);
            },
            getProductMetadataBatch,
            getWorkflowActivityInstance,
            resetGiftOrderInfo,
            resubmitOrder,
            retrieveCandidateBillCycles,
            retrieveConvergentBillerOrderDetails,
            retrieveOfferingsMetadata,
            retrieveOrderContext,
            retrieveOrderServiceDetails,
            searchSubscriberOfferings,
            setCurrentTab,
            setOrderItemToDisplayServiceAttributesFor,
            setResendGiftOrderInfo,
            stateGo,
            stateReload,
            submitResendGiftNotification,
            updateFdoExecutionDate,
            updateImportBulkServiceAttributesJobsPendingUpdatedStatus,
            updateLifeCycleDetails
        };
        this.disconnectRedux = this.$ngRedux.connect(mapStateToTarget, controllerActions)((state, actions) => {
            this.state = state;
            this.actions = actions;
        });
        this.stateOrName = 'index.customercare.customer.orderDetails';
        this.optionalParams = {
            customerId: this.state.currentCustomerId,
            currentOrderId: this.state.currentOrderId
        };

        this.displayBulkOrderStatus = (item) => {
            return item.IsBulkWithServices && item.BulkExtractItemDetail &&
                item.BulkExtractItemDetail.BulkExportStatus !== IMPORT_BULK_SERVICE_ATTRIBUTES_JOB_STATUSES.NOT_STARTED;
        };

        this.enableRefreshStatusLink = (item) => {
            const isPendingUpdatedStatus = this.bulkServiceAttributesJobIsPendingUpdatedStatus(item.pricingPlanId);

            return (
                item.BulkExtractItemDetail &&
                IMPORT_BULK_SERVICE_ATTRIBUTES_JOB_PENDING_STATUSES.includes(item.BulkExtractItemDetail.BulkExportStatus)
            ) || isPendingUpdatedStatus;
        };

        this.displayOrderItemWorkflowStatus = (item) => {
            return !!item.WorkflowInstanceId && !item.IsBulkWithServices;
        };

        this.summaryTabs = [{
            active: false,
            id: 1,
            glyph: 'credit-card-alt',
            title: this.CustomerLocaleKeys.ORDER_DETAILS.PAYMENT_SUMMARY
        }, {
            active: false,
            id: 2,
            glyph: 'truck',
            title: this.CustomerLocaleKeys.ORDER_DETAILS.SHIPPING_SUMMARY
        }];

        this.actions.setCurrentTab(1);

        if (!this.state.areBulkTypesLoaded) {
            this.actions.fetchBulkChargeTypes();
        }
        if (!this.state.arePeriodTypesLoaded) {
            this.actions.fetchPeriodTypes();
        }
        if (!this.state.storesLoaded) {
            this.actions.fetchCodeTypes(CODES.Stores);
        }
        if (!this.state.fdoConfigurationLoaded) {
            this.actions.fetchFdoConfiguration();
        }
        if (this.state.isDbss) {
            this.actions.retrieveCandidateBillCycles(this.state.currentCustomerId, SubscriberOrderTypeEnum.postpaid.value);
        }
        if (!this.state.isServiceReasonCodeLoaded) {
            this.actions.fetchDisconnectReasons();
        }
        if (!this.state.isOrderItemChangeLoaded) {
            this.actions.fetchOrderItemChangeType();
        }
        this.actions.fetchServiceAttributes();
        this.actions.fetchWorkflowActivityStates();
        this.actions.retrieveConvergentBillerOrderDetails(this.state.currentCustomerId, this.state.currentOrderId)
            .then((response) => {
                this.actions.getProductMetadataBatch(this.state.currentOrderProductIds);
                const offeringId = response.Order.Items[0].OfferingId;

                this.showAppointmentDetails = !!(response.Order.Items || [])
                    .filter(({AppointmentReservations}) => {
                        return AppointmentReservations?.length;
                    }).length;

                if (!this.state.offeringMetadataById[offeringId] && offeringId) {
                    this.actions.fetchObriConfig();
                    this.actions.fetchRbriConfig();
                    this.actions.retrieveOfferingsMetadata([offeringId]);
                }
                // this call depends on the order details being filled out
                if (this.state.currentOrderDetails.Workflows.length) {
                    this.actions.getWorkflowActivityInstance(this.state.currentOrderDetails.Workflows.map((wf) => {
                        return wf.Id;
                    }));
                }

                if (this.state.isGiftRedemptionOrder) {
                    this.summaryTabs.splice(0, 1);
                    if (!this.state.currentOrderHasShippingAddress) {
                        this.summaryTabs.splice(0, 1);
                    }
                } else {
                    if (!this.state.currentOrderHasShippingAddress) {
                        this.summaryTabs.splice(1, 1);
                    }
                    if (!this.state.currentOrderDetailsPaymentInstrumentSelector.length) {
                        this.summaryTabs.splice(0, 1);
                    }
                }
                if (this.summaryTabs.length > 0) {
                    this.summaryTabs[0].active = true;
                }
            });
        this.actions.retrieveOrderServiceDetails(this.state.currentOrderId, this.state.currentCustomerId);

        this.canViewTransactionDetails = hasReadAccess(this.state.userSecurityAttributes, PAYMENT_ACTIVITY_VIEW_ACCESS.id);

        this.additionalPropertiesModalConfig = {
            onRegisterApi: ({api}) => {
                this.additionalPropertiesModalAPI = api;
            }
        };

        this.applyCreditModalConfig = {
            onRegisterApi: ({api}) => {
                this.applyCreditModalApi = api;
            }
        };

        this.attributeModalConfig = {
            onRegisterApi: (event) => {
                this.attributeModalAPI = event.api;
            }
        };

        this.exportBulkServiceAttributesModalConfig = {
            onRegisterApi: ({api}) => {
                this.exportBulkServiceAttributesModalApi = api;
            }
        };

        this.bulkDetailsConfig = {
            onRegisterApi: ({api}) => {
                this.bulkDetailsApi = api;
            }
        };

        this.cancelOrderModalConfig = {
            onRegisterApi: ({api}) => {
                this.cancelOrderModalApi = api;
            }
        };

        this.completeOrderConfig = {
            onRegisterApi: ({api}) => {
                this.completeOrderApi = api;
            }
        };

        this.instancePropertiesModalConfig = {
            onRegisterApi: ({api}) => {
                this.instancePropertiesModalAPI = api;
            }
        };

        this.resendGiftNotificationModalConfig = {
            onRegisterApi: ({api}) => {
                this.resendGiftNotificationModalApi = api;
            }
        };

        this.resubmitConfirmationConfig = {
            onRegisterApi: ({api}) => {
                this.resubmitConfirmationApi = api;
            }
        };

        this.revokeGiftOrderPopupConfig = {
            onRegisterApi: ({api}) => {
                this.revokeGiftOrderPopupApi = api;
            }
        };

        this.importBulkServiceAttributesModalConfig = {
            modal: true,
            onRegisterApi: ({api}) => {
                this.importBulkServiceAttributesModalApi = api;
            }
        };

        this.lifeCycleDetailsPopupConfig = {
            onRegisterApi: ({api}) => {
                this.lifeCycleDetailsPopupConfigApi = api;
            }
        };

        this.contractTermsConditionsPopupConfig = {
            onRegisterApi: (event) => {
                this.contractTermsConditionsPopupApi = event.api;
            }
        };

        this.openContractTermsConditionsPopup = () => {
            this.contractTermsConditionsPopupOpened = true;
            this.$timeout(() => {
                this.contractTermsConditionsPopupApi.open();
            });
        };

        this.handleCloseContractTermsConditionsPopup = () => {
            this.contractTermsConditionsPopupApi.close();
            this.contractTermsConditionsPopupOpened = false;
        };

        this.changeFdoDatePopupConfig = {
            onRegisterApi: (event) => {
                this.changeFdoDatePopupApi = event.api;
            }
        };

        this.discountDetailsPopupConfig = {
            onRegisterApi: (event) => {
                this.discountDetailsPopupApi = event.api;
            }
        };

        this.handleChangeFdoDatePopup = () => {
            this.changeFdoDatePopupApi.close();
            this.changeFdoDatePopupOpened = false;
        };

        this.backBannerInfo = this.createBannerInfo();
        this.shouldShowBackBanner = this.backBannerInfo[this.state.routePreviousState.name];
    }

    $onDestroy() {
        this.actions.clearImportBulkServiceAttributesJobsPendingUpdatedStatus();

        this.disconnectRedux();
    }

    isBillingEffectiveDateShown() {
        return hasAccess(this.state.userSecurityAttributes, OVERRIDE_BILLING_EFFECTIVE_DATE) && this.state.currentOrderDetails.FutureDatedOrder;
    }

    openLifeCycleDetailsPopup(option, savedLifeCycleId) {
        this.actions.updateLifeCycleDetails({
            offerId: option.OfferingId,
            lifeCycleId: savedLifeCycleId
        });
        this.pricingPlanInLifeCycleForOffers = this.state.pricingPlanInLifeCycleForOffers;
        this.lifeCyclePricingPlanDetails = Object.assign({}, ...this.state.lifeCyclePricingPlanDetails);
        this.lifeCycleDialogOpened = true;
        this.currentLifeCycle = option.pricingPlanId;
        this.$timeout(() => {
            this.lifeCycleDetailsPopupConfigApi.open();
        });
    }

    closeLifeCycleDetailsPopup() {
        this.lifeCycleDialogOpened = false;
        this.lifeCycleDetailsPopupConfigApi.close();
    }

    openFutureDatedOrderPopup() {
        this.changeFdoDatePopupOpened = true;
        this.$timeout(() => {
            this.changeFdoDatePopupApi.open();
        });
    }

    openDiscountDetailsPopup(item) {
        this.currentDiscountDetails = item.Discounts;
        this.showDiscountDetailsPopup = true;
        this.$timeout(() => {
            this.discountDetailsPopupApi.open();
        });
    }

    closeDiscountDetailsPopup() {
        this.showDiscountDetailsPopup = false;
        this.discountDetailsPopupApi.close();
    }

    openChangeFdoDatePopup(isBillingEffectiveDateClick) {
        if (this.state.fdoAllowedInBunt && this.state.showExecutionDate) {
            if (this.isBillingEffectiveDateShown()) {
                this.actions.retrieveOrderContext(this.state.currentOrderDetails)
                    .then(() => {
                        this.openFutureDatedOrderPopup();
                    }).catch((error) => {
                        this.uiNotificationService.transientError(error.translatedMessage);
                    });
            } else if (!isBillingEffectiveDateClick) {
                this.openFutureDatedOrderPopup();
            }
        }
    }

    handleActionItemsSelected(selectedItem, actionsItem) {
        switch (actionsItem.item.id) {
            case ACTIONS_ITEMS.IMPORT_SERVICE_ATTRIBUTES_ID:
                this.openImportBulkServiceAttributesModal(selectedItem);
                break;
            case ACTIONS_ITEMS.VIEW_APPOINTMENT_DETAILS:
                this.onOpenAppointmentDetailsModal(selectedItem.Id);
                break;
            case ACTIONS_ITEMS.VIEW_PRICING_SCHEDULE_ID:
                this.openBulkDetailsModal(selectedItem);
                break;
            case ACTIONS_ITEMS.VIEW_SERVICES_ATTRIBUTES_ID:
                this.openServiceAttributes(selectedItem);
                break;
            case ACTIONS_ITEMS.VIEW_SERVICES_ID:
                this.openBulkServices(selectedItem);
                break;
            case ACTIONS_ITEMS.EXPORT_SERVICES_TEMPLATE_ID:
                this.openExportBulkServiceAttributesModal(selectedItem);
                break;
            default:
                break;
        }
    }

    handleMoreOptionsMenuSelected(selectedItem) {
        switch (selectedItem.item.id) {
            case ORDER_DETAILS_MORE_MENU_CONSTANTS.CANCEL_ORDER:
                this.openCancelOrderModal();
                break;
            case ORDER_DETAILS_MORE_MENU_CONSTANTS.COMPLETE_ORDER:
                this.openCompleteOrderModal();
                break;
            case ORDER_DETAILS_MORE_MENU_CONSTANTS.CREDIT_ORDER:
                this.openCreditModal();
                break;
            case ORDER_DETAILS_MORE_MENU_CONSTANTS.RESEND_GIFT_NOTIFICATION:
                this.openResendGiftNotificationModal();
                break;
            case ORDER_DETAILS_MORE_MENU_CONSTANTS.RESUBMIT_ORDER:
                this.openResubmitConfirmation();
                break;
            case ORDER_DETAILS_MORE_MENU_CONSTANTS.REVOKE_GIFT:
                this.openRevokeGiftOrderModal();
                break;
            case ORDER_DETAILS_MORE_MENU_CONSTANTS.EDIT_OFFER:
                this.navigateToFdoWizard();
                break;
            default:
                break;
        }
    }

    openCompleteOrderModal() {
        this.showCompleteOrder = true;
        this.$timeout(this.completeOrderApi.open);
    }

    closeCompleteOrderModal() {
        this.showCompleteOrder = false;
        this.completeOrderApi.close();
    }

    handleCompleteOrderResult(result) {
        this.closeCompleteOrderModal();
        if (result) {
            this.actions.completeOrder(this.state.currentCustomerId, this.state.currentOrderId)
                .then(() => {
                    this.uiNotificationService.success(i18n.translate(CustomerLocaleKeys.ORDER_DETAILS.COMPLETE_ORDER_SUCCESS), null, {
                        timeOut: NOTIFICATION_TIME_LENGTH
                    });
                })
                .catch((payload) => {
                    this.uiNotificationService.error(payload.translatedMessage, null, {
                        timeOut: NOTIFICATION_TIME_LENGTH
                    });
                });
        }
    }

    closeAdditionalPropertiesModal() {
        this.additionalPropertiesModalAPI.close();
        this.showAdditionalPropertiesModal = false;
    }

    closeInstancePropertiesModal() {
        this.instancePropertiesModalAPI.close();
        this.showInstancePropertiesModal = false;
    }

    closeAttributeModal() {
        this.attributeModalAPI.close();
        this.showAttributeModal = false;
    }

    onTabSelection(tab) {
        this.actions.setCurrentTab(this.state.tabs.indexOf(tab) + 1);
    }

    onSummaryTabSelection(tab) {
        this.summaryTabs.forEach((t) => {
            t.active = t === tab;
        });
    }

    handleCancelOrderModalResult(cancel) {
        this.cancelOrderModalApi.close();
        this.showCancelOrderModal = false;
        if (cancel) {
            Promise.resolve()
                .then(() => {
                    return this.state.currentOrderDetails.FutureDatedOrder ? this.actions.cancelPendingOrder(this.state.currentCustomerId, this.state.currentOrderId) :
                        this.actions.cancelOrder(this.state.currentCustomerId, this.state.currentOrderId, true);
                }).then(() => {
                    this.uiNotificationService.success(i18n.translate(this.CustomerLocaleKeys.ORDER_CANCELED_SUCCESSFULLY), null, {
                        timeOut: NOTIFICATION_TIME_LENGTH
                    });
                    this.actions.retrieveConvergentBillerOrderDetails(this.state.currentCustomerId, this.state.currentOrderId);
                    this.actions.retrieveOrderServiceDetails(this.state.currentOrderId, this.state.currentCustomerId);
                }).catch((payload) => {
                    this.uiNotificationService.error(payload.translatedMessage, null, {
                        timeOut: NOTIFICATION_TIME_LENGTH
                    });
                });
        }
    }

    closeBulkDetailsModal() {
        this.bulkDetailsApi.close();
        delete this.selectedBulkOrder;
    }

    openRevokeGiftOrderModal() {
        this.showRevokeGiftOrderDialog = true;

        this.$timeout(() => {
            this.revokeGiftOrderPopupApi.open();
        });
    }

    redirectToFdoWizard(offeringId, offeringInstanceId) {
        if (this.state.currentOrderDetails.Type === ORDER_TYPES.NEW) {
            this.actions.stateGo(this.state.currentOrderDetails.InstanceLevelOrdered ? ADD_OFFERING_INSTANCES : ADD_OFFERING, {
                futureDatedOrderId: this.state.currentOrderDetails.Id,
                futureDatedOfferingId: offeringId,
            });
        }

        if (this.state.currentOrderDetails.Type === ORDER_TYPES.CHANGE_OF_SERVICE) {
            this.actions.stateGo(this.state.currentOrderDetails.InstanceLevelOrdered ? EDIT_OFFERING_INSTANCES : EDIT_OFFERING, {
                offeringId: offeringId,
                offeringInstanceId: offeringInstanceId,
                futureDatedOrderId: this.state.currentOrderDetails.Id
            });
        }
    }

    navigateToFdoWizard() {
        const currentOfferingInstanceId = (this.state.currentOrderDetails?.Items || []).find((orderItem) => {
            return orderItem.OfferingInstanceId;
        })?.OfferingInstanceId;

        let currentOrderOfferingId = (this.state.currentOrderDetails?.Items || []).find((orderItem) => {
            return orderItem.OfferingId;
        })?.OfferingId;

        if (currentOrderOfferingId) {
            this.redirectToFdoWizard(currentOrderOfferingId, currentOfferingInstanceId);
        } else {
            /**
             * Service feature only FDO. OfferingId will not be available on RetrieveOrder items.
             * We need to call searchSubscriberOfferings to get offeringId using offeringInstanceId
             */
            this.actions.searchSubscriberOfferings({
                customerId: this.state.routeParams.customerId,
                status: [
                    OFFERING_STATUS_TYPES.ACTIVE,
                    OFFERING_STATUS_TYPES.PENDING_ACTIVE,
                    OFFERING_STATUS_TYPES.PENDING_REMOVED,
                    OFFERING_STATUS_TYPES.PENDING_TRANSFER,
                    OFFERING_STATUS_TYPES.SUSPENSION_SCHEDULED
                ],
                offeringInstanceIds: [currentOfferingInstanceId]
            }).then((response) => {
                currentOrderOfferingId = (response.SubscriberOfferings || []).find((offering) => {
                    return offering.OfferingInstanceId === currentOfferingInstanceId;
                })?.OfferingId;
                this.redirectToFdoWizard(currentOrderOfferingId, currentOfferingInstanceId);
            }).catch((error) => {
                this.uiNotificationService.transientError(error.translatedMessage);
            });
        }
    }

    closeRevokeGiftOrderModal() {
        this.showRevokeGiftOrderDialog = false;
        this.revokeGiftOrderPopupApi.close();
    }

    handleSuccessRevokeGiftOrderDialog() {
        this.actions.retrieveConvergentBillerOrderDetails(this.state.currentCustomerId, this.state.currentOrderId);
        this.uiNotificationService.success(i18n.translate(CustomerLocaleKeys.ORDER_DETAILS.REVOKE_GIFT_SUCCESS), null, {
            timeOut: NOTIFICATION_TIME_LENGTH
        });
        this.revokeGiftOrderPopupApi.close();
    }

    handleWorkflowExpansion(workflow) {
        workflow.isExpanded = !workflow.isExpanded;
    }
    openCancelOrderModal() {
        this.showCancelOrderModal = true;
        this.cancelOrderTitle = i18n.translate(CustomerLocaleKeys.ORDER_DETAILS.ARE_YOU_SURE_YOU_WANT_TO_CANCEL_ORDER, {
            Id: this.state.currentOrderDetails.OrderNumber
        });
        this.$timeout(this.cancelOrderModalApi.open);
    }
    openBulkDetailsModal(item) {
        this.selectedBulkOrder = item;
        this.$timeout(this.bulkDetailsApi.open);
    }
    openBulkServices(item) {
        this.actions.stateGo(SERVICES_AND_SHARED_ENTITLEMENTS, {
            tabName: SERVICES_AND_SHARED_ENTITLEMENTS_TAB.SERVICES,
            orderItemId: item.Id,
            orderId: this.state.currentOrderDetails.Id
        });
    }
    openAdditionalProperties() {
        this.additionalPropertyModalContentTitle = i18n.translate(CustomerLocaleKeys.ORDER_DETAILS.ORDER_NUMBER_TITLE, {
            orderNumber: this.state.currentOrderDetails.OrderNumber
        });
        this.showAdditionalPropertiesModal = true;
        this.$timeout(this.additionalPropertiesModalAPI.open);
    }
    openInstancePropertiesDialog(item) {
        this.instancePropertyModalContentTitle = item.displayName;
        this.orderItemOfInterest = {
            instanceProperties: {}
        };
        forEachObjIndexed((value, keyLabel) => {
            if (keyLabel.match(/Date/i)) {
                this.orderItemOfInterest.instanceProperties[keyLabel] = this.$filter('date')(value, 'shortDate');
            } else {
                this.orderItemOfInterest.instanceProperties[keyLabel] = value;
            }
        }, item.instanceProperties);
        this.showInstancePropertiesModal = true;
        this.$timeout(this.instancePropertiesModalAPI.open);
    }
    openServiceAttributes(item) {
        const orderItem = this.state.currentOrderServiceDetails[this.state.currentOrderId].filter((orderItem) => {
            return orderItem.OrderItemId === item.Id;
        })[0];

        this.editAttributes = orderItem.ServiceAttributeValues;
        this.availableAttributes = orderItem.AvailableServiceAttributes;
        this.orderItemId = orderItem.OrderItemId;

        this.attributeModalContentTitle = item.displayName;
        this.showAttributeModal = true;
        this.$timeout(this.attributeModalAPI.open);
    }

    openExportBulkServiceAttributesModal(orderItem) {
        this.currentOrderBulkServicesDetails = {
            customerId: this.state.currentCustomerId,
            jobId: pathOr(null, ['BulkExtractDetail', 'Id'], this.state.currentOrderDetails),
            offeringId: orderItem.OfferingId,
            orderId: this.state.currentOrderDetails.Id,
            pricingPlanId: orderItem.pricingPlanId,
            pricingPlanName: orderItem.displayName
        };
        this.showExportBulkServiceAttributesModal = true;
        this.$timeout(this.exportBulkServiceAttributesModalApi.open);
    }

    closeExportBulkServiceAttributesModal() {
        this.showExportBulkServiceAttributesModal = false;
        this.exportBulkServiceAttributesModalApi.close();
    }

    workflowActivityForItem(item) {
        return pathOr(null, ['workflowActivities', item.WorkflowInstanceId, 'workflowInstance', 'activities', 0], this.state);
    }

    getItemMatchingWorkflow(workflow) {
        const item = this.state.currentOrderDetails.Items.find((orderItem) => {
            return orderItem.WorkflowInstanceId && orderItem.WorkflowInstanceId === workflow.Id;
        });
        return item || null;
    }

    openResendGiftNotificationModal() {
        this.actions.setResendGiftOrderInfo(this.state.currentOrderGiftOrderInfo);
        this.$timeout(this.resendGiftNotificationModalApi.open);
    }
    closeResendGiftNotificationModal() {
        this.actions.resetGiftOrderInfo();
        this.resendGiftNotificationModalApi.close();
    }
    submitResendGiftNotificationModal(giftOrderInfo) {
        this.actions.submitResendGiftNotification(giftOrderInfo)
            .then(() => {
                this.uiNotificationService.success(i18n.translate(CustomerLocaleKeys.ORDER_DETAILS.RESEND_SUCCESS), null, {
                    timeOut: NOTIFICATION_TIME_LENGTH
                });
                this.actions.retrieveConvergentBillerOrderDetails(this.state.currentCustomerId, this.state.currentOrderId);
                this.closeResendGiftNotificationModal();
            });
    }
    editResendGiftNotificationModal(giftOrderInfo) {
        this.actions.setResendGiftOrderInfo(giftOrderInfo);
    }
    openResubmitConfirmation() {
        this.showResubmitConfirmation = true;
        this.$timeout(this.resubmitConfirmationApi.open);
    }
    onResubmitOrderConfirmationResponse(response) {
        this.showResubmitConfirmation = false;
        this.resubmitConfirmationApi.close();
        if (response) {
            this.actions.resubmitOrder(this.state.currentCustomerId, this.state.currentOrderId)
                .then(() => {
                    this.uiNotificationService.success(i18n.translate(CustomerLocaleKeys.ORDER_DETAILS.RESUBMIT_ORDER_SUCCESS), null, {
                        timeOut: NOTIFICATION_TIME_LENGTH
                    });
                })
                .catch((payload) => {
                    this.uiNotificationService.error(payload.translatedMessage, null, {
                        timeout: NOTIFICATION_TIME_LENGTH
                    });
                });
        }
    }
    closeCreditModal() {
        this.showCreditModal = false;
        this.applyCreditModalApi.close();
    }
    onCreditSubmission() {
        this.actions.retrieveConvergentBillerOrderDetails(this.state.currentCustomerId, this.state.currentOrderId);
        this.closeCreditModal();
        this.actions.stateReload().then(() => {
            this.showLoadingIndicator = false;
        });
    }
    openCreditModal(item) {
        this.additionalApiData = {
            OrderId: this.state.currentOrderDetails.Id
        };

        if (item) {
            this.additionalApiData.OrderItemId = item.Id;
            this.creditMaxAmount = !isNil(item.ReturnAmount) ? (item.Amount + item.ReturnAmount) : item.Amount;
            this.creditModalTitle = i18n.translate(CustomerLocaleKeys.ORDER_DETAILS.CREDIT.ORDER_ITEM_TITLE, {
                itemName: item.displayName
            });
        } else {
            this.creditMaxAmount = this.maxOrderCreditAmount;
            this.creditModalTitle = i18n.translate(CustomerLocaleKeys.ORDER_DETAILS.CREDIT.ORDER_NUMBER_TITLE, {
                orderNumber: this.state.currentOrderDetails.OrderNumber
            });
        }
        this.showCreditModal = true;
        this.$timeout(this.applyCreditModalApi.open);
    }
    onCloseAppointmentDetailsModal() {
        this.showAppointmentDetailsModal = false;
        this.orderItemsWithAppointments = [];
    }
    onOpenAppointmentDetailsModal(orderItemId) {
        this.orderItemsWithAppointments = orderItemId ? this.state.currentOrderDetails.Items.filter(({Id}) => {
            return orderItemId === Id;
        }) : this.state.currentOrderDetails.Items;
        this.showAppointmentDetailsModal = true;
    }

    onBannerClose() {
        this.shouldShowBackBanner = false;
    }

    backBannerText() {
        return this.backBannerInfo[this.state.routePreviousState.name](this.state);
    }

    subscriptionSecondaryNavigationLabel(state) {
        const previousName = state.previousPageSubscription.offeringThumbnail ? state.previousPageSubscription.offeringThumbnail.Name : state.previousPageSubscription.Items[0].Product.Name;
        return i18n.translate(CustomerLocaleKeys.TRANSACTIONS.RETURN_TO, {
            previousName
        });
    }

    get maxOrderCreditAmount() {
        return pathOr(0, ['state', 'currentOrderTransaction', 'PurchaseOrder', 'Totals', 'TotalCreditableAmount'], this);
    }

    getOrderHistoryHeading(item) {
        return item.OfferingName || i18n.translate(CustomerLocaleKeys.PRODUCT);
    }

    createBannerInfo() {
        const info = {};
        info[SUBSCRIPTION_DETAILS_ROUTE] = this.subscriptionSecondaryNavigationLabel;
        info[GRANTED_COUPON_ROUTE] = () => {
            return i18n.translate(CustomerLocaleKeys.COUPONS.RETURN_TO_GRANTED_COUPONS);
        };
        info[TRANSACTION_DETAILS_STATE] = () => {
            return i18n.translate(CustomerLocaleKeys.TRANSACTION_DETAILS.RETURN_TO_TRANSACTION_DETAILS);
        };
        info[COUPON_DETAILS_ROUTE] = () => {
            return i18n.translate(CustomerLocaleKeys.COUPONS.RETURN_TO_COUPON_DETAILS);
        };
        info[COUPON_DETAILS_GLOBAL_ROUTE] = () => {
            return i18n.translate(CustomerLocaleKeys.COUPONS.RETURN_TO_COUPON_DETAILS);
        };
        info[PRODUCT_STATE] = () => {
            return i18n.translate(CustomerLocaleKeys.ORDER_DETAILS.RETURN_TO_PRODUCT_DETAILS);
        };

        return info;
    }

    bulkServiceAttributesJobIsPendingUpdatedStatus(pricingPlanId) {
        return !!(this.state.bulkServiceAttributesJobsPendingUpdatedStatus[pricingPlanId]);
    }

    openImportBulkServiceAttributesModal(orderItem) {
        this.currentOrderBulkServicesDetails = {
            customerId: this.state.currentCustomerId,
            jobId: pathOr(null, ['BulkExtractDetail', 'Id'], this.state.currentOrderDetails),
            offeringId: orderItem.OfferingId,
            orderId: this.state.currentOrderDetails.Id,
            pricingPlanId: orderItem.pricingPlanId,
            pricingPlanName: orderItem.displayName || orderItem.pricingPlanName
        };
        this.showImportBulkServiceAttributesModal = true;

        this.$timeout(this.importBulkServiceAttributesModalApi.open);
    }

    closeImportBulkServiceAttributesModal() {
        this.showImportBulkServiceAttributesModal = false;

        this.importBulkServiceAttributesModalApi.close();
    }

    refreshItemForBulkOrderStatus(item) {
        if (this.enableRefreshStatusLink(item)) {
            this.actions.retrieveConvergentBillerOrderDetails(this.state.currentCustomerId, this.state.currentOrderId)
                .then(() => {
                    const jobsToBeRemoved = returnExpiredImportBulkServiceAttributesJobsPendingUpdatedStatus(
                        this.state.currentOrderDisplayItems,
                        this.state.bulkServiceAttributesJobsPendingUpdatedStatus
                    );

                    this.actions.updateImportBulkServiceAttributesJobsPendingUpdatedStatus(jobsToBeRemoved);
                });
        }
    }

    handleSuccessChangeFdo() {
        this.actions.updateFdoExecutionDate(this.state.executionOptions, this.state.currentOrderId).then(() => {
            this.handleChangeFdoDatePopup();
            this.showLoadingIndicator = true;
            this.actions.stateReload().then(() => {
                this.showLoadingIndicator = false;
            });
        }).catch((error) => {
            this.uiNotificationService.transientError(error.translatedMessage);
        });
    }

    openGroupOrderDetailsInNewWindow() {
        NewWindow.open('index.customercare.customer.orderHistory.groupOrderDetails', {
            groupOrderId: this.state.currentOrderDetails.GroupOrderId,
            customerId: this.state.currentCustomerNode.ParentSubscriberId
        });
    }
}

export default {
    template: require('./order.details.html'),
    controller: OrderDetailsController,
    controllerAs: 'OrderDetails'
};
