import {
    clearRemoveOrderQuote,
    emptySubscriptionsList,
    removeSubscriptionsFilter,
    RETRIEVE_SUBSCRIPTION_CONSTANTS,
    setEditSubscriptionItemId
} from '../../../reducers/actions/customer.subscriptions.actions';
import {stateGo} from 'redux-ui-router';
import {ORDERING_ACCESS, SUBSCRIPTION_ACCESS} from './../../../security.attributes';
import {
    PRODUCT_ORDER_WIZARD_MODE
}
    from '../../../reducers/constants/products.wizard.constants';
import {CurrentSubscriptionSelector} from '../../../reducers/selectors/customer.subscriptions.selectors';
import LocaleKeys from '../../../locales/keys';

export const SUBSCRIPTION_DETAILS_ROUTE = 'index.customercare.customer.subscriptions.detail.view';
export const SUBSCRIPTION_RESTORE_ROUTE = 'index.customercare.customer.subscriptions.detail.restore';
import {SUBSCRIPTION_LIST_ROUTE} from '../../../reducers/constants/dashboard.constants';
import {NAVIGATE_TO_CART, STORE_MODIFY_REQUEST_DATA} from '../../../reducers/actions/products.order.actions';

export function routing($stateProvider, $provide) {
    $stateProvider.state('index.customercare.customer.subscriptions', {
        url: '/subscriptions',
        redirectTo: 'index.customercare.customer.subscriptions.list',
        template: '<ui-view class="u-flexKeepHeight"></ui-view>',
        securityAttributes: [SUBSCRIPTION_ACCESS],
        onExit: ($ngRedux) => {
            $ngRedux.dispatch(removeSubscriptionsFilter());
            $ngRedux.dispatch(emptySubscriptionsList());
        }
    }).state(SUBSCRIPTION_LIST_ROUTE, {
        url: '/list',
        template: '<subscriptions-list></subscriptions-list>'
    }).state('index.customercare.customer.subscriptions.detail', {
        url: '/:subscriptionId',
        abstract: true,
        template: '<ui-view class="u-flexKeepHeight"></ui-view>'
    }).state(SUBSCRIPTION_DETAILS_ROUTE, {
        url: '/view?{goToCheckoutForRestoreSubscription}',
        template: '<subscription-detail></subscription-detail>'
    }).state('index.customercare.customer.subscriptions.detail.replace', {
        url: '/replaceOrder/?:childSubscriptionId',
        params: {
            childSubscriptionId: ''
        },
        template: '<products-wizard></products-wizard>',
        adminSecurityAttributes: [ORDERING_ACCESS],
        params: {
            mode: PRODUCT_ORDER_WIZARD_MODE.REPLACE
        }
    }).state('index.customercare.customer.subscriptions.detail.modify', {
        url: '/modify/{childSubscriptionId}',
        template: '<products-wizard></products-wizard>',
        adminSecurityAttributes: [ORDERING_ACCESS],
        params: {
            mode: PRODUCT_ORDER_WIZARD_MODE.MODIFY,
            childSubscriptionId: {
                value: '',
                squash: true
            }
        },
        resolve: {
            reactModifyOrder: ($q, $ngRedux) => {
                const invReactModifySubscriptionOrder = localStorage.getItem('invReactModifySubscriptionOrder');
                if (invReactModifySubscriptionOrder) {
                    localStorage.removeItem('invReactModifySubscriptionOrder');
                    const orderData = JSON.parse(invReactModifySubscriptionOrder);

                    $ngRedux.dispatch({
                        type: RETRIEVE_SUBSCRIPTION_CONSTANTS.SUCCESS,
                        payload: {
                            Subscription: orderData.subscription
                        }
                    });
                    $ngRedux.dispatch({
                        type: NAVIGATE_TO_CART
                    });
                    $ngRedux.dispatch({
                        type: STORE_MODIFY_REQUEST_DATA,
                        requestObject: {
                            AddItems: orderData.modifyRequest.AddItems,
                            ChangeItems: orderData.modifyRequest.ChangeItems,
                            RemoveItems: orderData.modifyRequest.RemoveItems,
                            ReplaceItems: orderData.modifyRequest.ReplaceItems
                        }
                    });
                }
                $q.resolve();
            }
        },
        onEnter: ($ngRedux, $stateParams, $timeout) => {
            const state = $ngRedux.getState();
            const currentSubscription = CurrentSubscriptionSelector(state);

            if (!currentSubscription) { // occurs when user refreshes page when on modify route
                $timeout(() => {
                    $ngRedux.dispatch(stateGo(SUBSCRIPTION_DETAILS_ROUTE, {
                        customerId: $stateParams.customerId,
                        subscriptionId: $stateParams.subscriptionId
                    }));
                });
            }
        },
        onExit: ($ngRedux) => {
            $ngRedux.dispatch(setEditSubscriptionItemId(null));
        }
    }).state(SUBSCRIPTION_RESTORE_ROUTE, {
        url: '/restore',
        template: '<products-wizard></products-wizard>',
        adminSecurityAttributes: [ORDERING_ACCESS],
        params: {
            mode: PRODUCT_ORDER_WIZARD_MODE.RESTORE
        },
        onEnter: ($ngRedux, $stateParams, $timeout) => {
            const state = $ngRedux.getState();
            const currentSubscription = CurrentSubscriptionSelector(state);

            if (!currentSubscription) { // occurs when user refreshes page when on restore route
                $timeout(() => {
                    $ngRedux.dispatch(stateGo(SUBSCRIPTION_DETAILS_ROUTE, {
                        customerId: $stateParams.customerId,
                        subscriptionId: $stateParams.subscriptionId
                    }));
                });
            }
        }
    }).state('index.customercare.customer.subscriptions.detail.remove', {
        url: '/remove/{childSubscriptionId}',
        params: {
            childSubscriptionId: {
                value: '',
                squash: true
            }
        },
        template: '<remove-subscription></remove-subscription>',
        adminSecurityAttributes: [SUBSCRIPTION_ACCESS],
        onExit: ($ngRedux) => {
            $ngRedux.dispatch(clearRemoveOrderQuote());
        }
    }).state('index.customercare.customer.subscriptions.detail.extension', {
        url: '/removeExtension/:extensionId/?currency',
        params: {
            currency: null
        },
        template: '<remove-extension-confirmation></remove-extension-confirmation>'
    });

    $provide.decorator('formErrors', ($delegate) => {
        $delegate.addErrorMessageLocaleKey('isCreditAmountValid', LocaleKeys.SUBSCRIPTIONS.REMOVE_EXTENSION.CUSTOM_AMOUNT_INVALID);

        return $delegate;
    });
}
